import React from 'react'
import {Icon} from 'antd'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Features = () => (
    <Layout>
         <SEO title="features" description="Explore the extensive features Emaildrop offers you to protect
         yourself from email abuse and spam."/>
        <main>
            <div className="main-wrapper">
                <h1 className="title">Features</h1>
                <div className="features">
                    <div className="contents">
                        <div className="content">
                            <h2>Why use Emaildrop?</h2>
                        </div>
                        <div className="features-list">
                            <div className="feature">
                                <h3>Completely free <Icon type="mail" theme="twoTone" /></h3>
                                <div className="paragraph">
                                    Emaildrop is a completely free service giving users the 
                                    ability to create unlimited email addresses and use 
                                    custom usernames. Even our API is free and all our endpoints 
                                    are public.
                                </div>
                            </div>
                            <div className="feature">
                                <h3>No set up needed <Icon type="cloud" theme="twoTone" /></h3>
                                <div className="paragraph">
                                    Emaildrop is the easiest way to recieve emails without ever 
                                    even needing to sign up or create a password for you account.
                                    There's no software to download either. Everything is already 
                                    set you for you to use!
                                </div>
                            </div>
                            <div className="feature">
                                <h3>Free and public API <Icon type="code" theme="twoTone" /></h3>
                                <div className="paragraph">
                                    We offer a free and public API for developers to use to create 
                                    disposable email addresses programmatically. None of our endpoints require
                                    authentication, so you can get started straight away!
                                </div>
                            </div>
                            <div className="feature">
                                <h3>Complete control <Icon type="setting" theme="twoTone" /></h3>
                                <div className="paragraph">
                                    You have complete control over you inbox with the ability to delete 
                                    email addresses as soon as they arrive. And if you forget, don't worry;
                                    all emails are deleted after 1 hour, so we never keep information on you.
                                </div>
                            </div>
                            <div className="feature">
                                <h3>Download attachments <Icon type="folder-open" theme="twoTone" /></h3>
                                <div className="paragraph">
                                    You can even download attachments from an email and save them to your local
                                    folder so don't worry about emails with attachments being rejected by Emaildrop.
                                </div>
                            </div>
                            <div className="feature">
                                <h3>Recieve emails in real time <Icon type="database" theme="twoTone" /></h3>
                                <div className="paragraph">
                                    All emails arrive in your inbox as soon as they hit our servers, so there's no need
                                    to keep hitting that refresh button :).
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
)

export default Features;